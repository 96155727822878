export const REACT_APP_NAME = "Gridley | Footy Grid Game for AFL Trivia Fans";
export const REACT_APP_DESC =
  "Play the popular new Footy Trivia game - Gridley! Complete your grid using AFL players from any era and earn rarity points for unique picks.";
export const REACT_APP_URL = "https://gridleygame.com/";
export const REACT_APP_IMG_URL =
  "https://gridleygame.com/img/gridley-social.png";

export function addProductJsonLd() {
  return {
    __html: `{
      "@context" : "https://schema.org",
      "@type" : "WebSite",
      "name" : "Gridley",
      "url" : "https://gridleygame.com/"
    }
  `,
  };
}
